<template>
  <div class="card">
    <div class="card-body">
      <div class="row success">
        <div class="col-xl-8">
          <h5 style="color: blue; margin-bottom: 30px">
            <span style="margin-right: 30px">
              {{ $t("menu.bulletinStatistique") }}</span
            >
          </h5>
        </div>
      </div>
      <div class="row">
        <div class="col-xl-12">
          <label style="margin-right: 5px; margin-left: 15px">{{
            this.$t("emploi.trimestre")
          }}</label>

          <a-select
            :placeholder="$t('paiement.selectionnerMois')"
            style="width: 50px; margin-left: 15px"
            @change="handleTrimesterChange"
            default-value="1"
          >
            <a-select-option
              v-for="trimester in ['1', '2', '3']"
              :key="trimester"
              :value="trimester"
            >
              {{ trimester }}
            </a-select-option>
          </a-select>
          <label style="margin-right: 5px; margin-left: 15px">{{
            $t("etablissement.type")
          }}</label>

          <a-select
            :placeholder="
              $t('etablissement.type') + ' ' + $t('bulletin.bulletin')
            "
            style="width: 220px; margin-left: 15px"
            @change="handleBTChange"
            default-value="MP"
          >
            <a-select-option v-for="b in ['MP', 'MS']" :key="b">
              {{ bText(b) }}
            </a-select-option>
          </a-select>
          <div style="float: right">
            <a-dropdown :disabled="printLoading || loadingStatistiques">
              <a-menu slot="overlay">
                <a-menu-item key="1" @click="print">
                  <a-icon type="file-pdf" />الإحصاء المدرسي
                </a-menu-item>
                <a-menu-item key="2" @click="printStat">
                  <a-icon type="bar-chart" />{{ $t("menu.statistique") }}
                </a-menu-item>
              </a-menu>
              <a-button
                type="primary"
                :loading="printLoading || loadingStatistiques"
                :disabled="printLoading || loadingStatistiques"
                ><a-icon type="printer" /> {{ $t("action.imprimer") }}
                <a-icon type="down" />
              </a-button>
            </a-dropdown>
          </div>
        </div>
      </div>

      <div v-if="loadingStatistiques">
        <a-row style="height: 20vh">
          <a-col></a-col>
        </a-row>
        <a-row>
          <a-col style="" :span="16" :offset="11">
            <a-spin size="large" />
          </a-col>
        </a-row>
        <a-row style="height: 20vh">
          <a-col></a-col>
        </a-row>
      </div>
      <div v-else>
        <vue-html2pdf
          :show-layout="true"
          :float-layout="false"
          :enable-download="true"
          :preview-modal="false"
          :paginate-elements-by-height="1000"
          filename="stats"
          :pdf-quality="2"
          :manual-pagination="false"
          pdf-format="a4"
          pdf-orientation="landscape"
          pdf-content-width="850"
          @beforeDownload="beforeDownload($event)"
          @hasDownloaded="hasDownloaded($event)"
          ref="html2Pdf"
        >
          <section slot="pdf-content" class="m-1">
            <!-- PDF Content Here -->
            <a-divider><h4>معدل الثلاثي العام</h4></a-divider>
            <div class="row mt-3">
              <div class="col-8">
                <MoyenneBars :data="this.statstiques.general" />
              </div>
              <div class="col-4">
                <MoyennePie :data="this.statstiques.general" />
              </div>
            </div>
            <div v-if="selectedBType != 'MT'">
              <a-divider
                ><h4>معدل الثلاثي العام في المواد الأساسية</h4></a-divider
              >
              <div class="row mt-3">
                <div class="col-8">
                  <MoyenneBars :data="rootStat['MT'].general" />
                </div>
                <div class="col-4">
                  <MoyennePie :data="rootStat['MT'].general" />
                </div>
              </div>
            </div>
            <a-tabs
              type="card"
              :default-active-key="statstiques.modules[0].name"
              @change="callbackTabChange"
            >
              <a-tab-pane
                v-for="module in this.statstiques.modules"
                :key="module.name"
                :tab="module.name"
              >
                <div
                  class="row"
                  v-if="activeKey == 1 || activeKey == module.name"
                >
                  <div
                    v-for="subject in module.subjects"
                    :key="subject.name"
                    class="col-6"
                  >
                    <SubjectBars :subject="subject" class="no-page-break" />
                  </div>
                </div>
              </a-tab-pane>
            </a-tabs>

            <!-- end content-->
          </section>
        </vue-html2pdf>
      </div>
    </div>
  </div>
</template>

<script>
/*eslint-disable */
//ANCHOR class/month filter to this view: DONE

import apiClient from "@/services/axios";
import { mapState } from "vuex";
import JsPDF from "jspdf";
import "jspdf-autotable";
import moment from "moment";
import SubjectBars from "./components/subjectBars";
import MoyenneBars from "./components/moyenneBars";
import MoyennePie from "./components/moyennePie";
import VueHtml2pdf from "vue-html2pdf";
export default {
  computed: {
    ...mapState(["settings", "user"]),
    statstiques: function () {
      return this.rootStat[this.selectedBType];
    },
  },
  components: { SubjectBars, MoyenneBars, MoyennePie, VueHtml2pdf },
  async created() {
    this.getStatistqueData();

    this.schoolType = this.user.building.filter(
      (el) => el.dbName == this.settings.activeBuilding
    )[0].type;
    if (!this.schoolType)
      notification.warning({
        message: this.$t("bulletin.typeIntrouvable"),
        description: this.$t("bulletin.saisirSchoolType"),
      });

    this.schoolDetails = this.user.building.filter(
      (el) => el.dbName == this.settings.activeBuilding
    )[0];
    let { start, end } = this.settings.schoolarYears.find(
      (el) => el._id == this.settings.activeSchoolarYear
    );

    start = new Date(start).getFullYear();
    end = new Date(end).getFullYear();

    this.schoolarYearName = end + " / " + start;
  },
  data() {
    return {
      schoolType: null,
      selectedTrimester: "1",
      rootStat: {},
      selectedBType: "MP",
      loadingStatistiques: false,
      printLoading: false,
      activeKey: 1,
      printLoading: false,
    };
  },
  methods: {
    bText(key) {
      switch (key) {
        case "MP":
          return this.$t("bulletin.bulletinPedagogique");
        case "MT":
          return this.$t("bulletin.bulletinSpecifique");
        case "MS":
          return this.$t("bulletin.bulletinVerySpecifique");
      }
    },
    hasDownloaded(event) {
      console.log("hasDownloaded ", event);
      this.printLoading = false;
    },
    async beforeDownload({ html2pdf, options, pdfContent }) {
      this.printLoading = true;
      await html2pdf()
        .set(options)
        .from(pdfContent)
        .toPdf()
        .get("pdf")
        .then((pdf) => {
          const totalPages = pdf.internal.getNumberOfPages();
          for (let i = 1; i <= totalPages; i++) {
            pdf.setPage(i);
            pdf.setFontSize(6);
            pdf.setTextColor(150);
            pdf.text(
              "Page " + i + " / " + totalPages,
              pdf.internal.pageSize.getWidth() * 0.88,
              pdf.internal.pageSize.getHeight() - 0.3
            );
          }
        })
        .save();
    },
    callbackTabChange: function (key) {
      this.activeKey = key;
    },
    getStatistqueData() {
      this.loadingStatistiques = true;
      apiClient
        .get("/bulletin/v2/stats/home/" + this.selectedTrimester)
        .then((res) => {
          this.rootStat = res.data;
        })
        .catch((e) => {
          console.error(e);
          this.$message.error(this.$t("error.error"));
        })
        .finally(() => {
          this.loadingStatistiques = false;
        });
    },
    handleTrimesterChange(val) {
      this.selectedTrimester = val;
      this.getStatistqueData();
    },
    handleBTChange(val) {
      this.selectedBType = val;
    },
    async printStat() {
      this.$gtag.event("Imp charts", {
        event_category: "Impression PDF",
        event_label: "Bulletin statstique section",
        value: 1,
      });

      this.printLoading = true;

      setTimeout(async () => {
        await this.$refs.html2Pdf.generatePdf();
      }, 100);
    },
    print() {
      this.$gtag.event("Imp bulletin statistique", {
        event_category: "Impression PDF",
        event_label: "Bulletin statstique section",
        value: 1,
      });

      this.printLoading = true;

      let arrayOfImages = this.settings.image;
      for (var i = 0; i < arrayOfImages.length; i++) {
        if (this.settings.activeBuilding === arrayOfImages[i].db) {
          var imgData = new Image();
          imgData.src = arrayOfImages[i].logo;
        }
      }

      const doc = new JsPDF({
        orientation: "l",
        unit: "mm",
        format: "a3",
      });

      const pdf_width = doc.internal.pageSize.getWidth();

      let titleTrimester = "";
      switch (this.selectedTrimester) {
        case "1":
          titleTrimester = "الثلاثي الأول";
          break;
        case "2":
          titleTrimester = "الثلاثي الثاني";
          break;
        case "3":
          titleTrimester = "الثلاثي الثالث";
          break;
      }

      const cellToDelete = {};
      let module_id = 0;

      for (const module of this.statstiques.modules) {
        if (module_id != 0) doc.addPage();

        doc.setFontSize(13);
        doc.setFont("Amiri", "Bold");
        doc.setTextColor("#505050");
        doc.text(
          "الجمهورية التونسية\nوزارة التربية\nالمندوبية الجهوية للتربية ب",
          pdf_width - 17,
          15,
          { align: "right" }
        );

        doc.setFontSize(12);

        doc.text(
          `نتائج تلاميذ جميع المستويات في ${module.name} خلال ${titleTrimester} للسنة الدراسية ${this.schoolarYearName}`,
          250,
          15,
          { align: "right" }
        );

        doc.text(
          `المدرسة الإبتدائية الخاصّة : ${
            this.schoolDetails.ArabicName || ".............."
          }`,
          250,
          25,
          {
            align: "right",
          }
        );

        doc.text(`جدول عدد ${++module_id}`, 18, 15);

        doc.addImage(imgData, "JPEG", pdf_width - 100, 10, 20, 20);

        for (const subject of module.subjects) {
          const array = [];
          let arr = [
            { content: "النسبة\nللتثبت", rowSpan: 3 },
            { content: "المجموع\nللتثبت", rowSpan: 3 },
            { content: "النسبة\nالمائوية", rowSpan: 3 },
            {
              content: "مجموع المتحصلين على معدل يساوي أو يفوق 10 من 20",
              rowSpan: 3,
            },
            { content: "النسبة\nالمائوية", rowSpan: 3 },
            {
              content: "مجموع المتحصلين على\n معدل دون 10 من 20",
              rowSpan: 3,
            },
            {
              content: subject.name,
              rowSpan: 1,
              colSpan: 10,
            },
            {
              content: "",
              lineWidth: 0,
              lineColor: "#ffffff",
              colSpan: 2,
            },
          ];
          array.push(arr);
          arr = [];
          for (let i = 0; i < 6; i++) arr.push(cellToDelete);
          arr.push({
            content: "يفوق 15",
            colSpan: 2,
          });
          arr.push({
            content: "يفوق10 الى 15 من 20",
            colSpan: 2,
          });
          arr.push({
            content: "ـ 10 من 20",
            colSpan: 2,
          });
          arr.push({
            content: "من 5 الى دون 10 من 20",
            colSpan: 2,
          });
          arr.push({
            content: "دون 5 من 20",
            colSpan: 2,
          });
          arr.push({
            content: "عدد التلاميذ",
            rowSpan: 2,
          });
          arr.push({
            content: "المستوى",
            rowSpan: 2,
          });
          array.push(arr);
          arr = [];
          for (let i = 0; i < 6; i++) arr.push(cellToDelete);
          for (let i = 0; i < 5; i++) {
            arr.push({
              content: "%النسبة",
            });
            arr.push({
              content: "العدد",
            });
          }
          arr.push(cellToDelete);
          arr.push(cellToDelete);
          array.push(arr);
          arr = [];

          for (const level of subject.levels) {
            arr = [];
            arr.push(level.toCheckStudentsAverage);
            arr.push(level.toCheckStudents);
            arr.push(level.moduleBetween10And20Average);
            arr.push(level.moduleBetween10And20);
            arr.push(level.moduleLessThan10Average);
            arr.push(level.moduleLessThan10);

            arr.push(level.subjectMoreThan15Average);
            arr.push(level.subjectMoreThan15);

            arr.push(level.subjectBetween10And15Average);
            arr.push(level.subjectBetween10And15);

            arr.push(level.subjectEqual10Average);
            arr.push(level.subjectEqual10);

            arr.push(level.subjectBetween5And10Average);
            arr.push(level.subjectBetween5And10);

            arr.push(level.subjectLessThan5Average);
            arr.push(level.subjectLessThan5);

            arr.push(String(level.students));
            arr.push(level.level + "س");
            array.push(arr);
            arr = [];
          }

          // fix table
          for (let row = 0; row < array.length; row++) {
            array[row] = array[row].filter((cell) => cell !== cellToDelete);
          }

          doc.autoTable({
            theme: "grid",
            styles: {
              font: "Amiri",
              halign: "right",
              fontSize: 11,
              halign: "center",
              valign: "middle",
              fontStyle: "Bold",
              lineColor: "#363636",
              lineWidth: 0.1,
              cellPadding: {
                right: 0,
                left: 0,
                top: 0.5,
                bottom: 0.5,
              },
            },
            pageBreak: "avoid",
            columnStyles: {
              0: { cellWidth: 22 },
              1: { cellWidth: 22 },
              2: { cellWidth: 22 },
              3: { cellWidth: 45 },
              4: { cellWidth: 22 },
              5: { cellWidth: 45 },
            },
            body: array,
            margin: { top: 35 },
            didDrawPage: function (data) {
              // Reseting top margin. The change will be reflected only after print the first page.
              data.settings.margin.top = 10;
            },
          });
        }
      }

      doc.addPage();

      let array = [];
      let arr = [
        {
          content: "عدد التلاميذ المتحصلين على معدل ثلاثي عام",
          colSpan: 10,
          styles: {
            fontSize: 20,
          },
        },
        {
          content: "",
          colSpan: 2,
          // styles: { lineWidth: { top: 0, left: 0 } },
        },
      ];
      array.push(arr);
      arr = [];
      arr.push({
        content: "النسبة المائوية",
        rowSpan: 2,
      });
      arr.push({
        content: "مجموع المتحصلين على معدّل",
        rowSpan: 2,
      });

      arr.push({
        content: "دون 10 من 20",
        colSpan: 2,
      });

      arr.push({
        content: "يتراوح بين 10 و 14.99",
        colSpan: 2,
      });

      arr.push({
        content: "يتراوح بين 15 و 15.99",
        colSpan: 2,
      });

      arr.push({
        content: "يساوي او يفوق 16",
        colSpan: 2,
      });

      arr.push({
        content: "عدد التلاميذ",
        rowSpan: 2,
      });

      arr.push({
        content: "المستوى",
        rowSpan: 2,
      });

      array.push(arr);

      arr = [];

      for (let i = 0; i < 4; i++) {
        arr.push({
          content: "%النسبة",
        });
        arr.push({
          content: "العدد",
        });
      }

      arr.push(cellToDelete);
      arr.push(cellToDelete);

      array.push(arr);

      for (const level of this.statstiques.general) {
        arr = [];
        arr.push(level.hasMoyenneAverage);
        arr.push(level.hasMoyenne);
        arr.push(level.lt10Average);
        arr.push(level.lt10);
        arr.push(level.between10And14Average);
        arr.push(level.between10And14);
        arr.push(level.between15And16Average);
        arr.push(level.between15And16);
        arr.push(level.gte16Average);
        arr.push(level.gte16);
        arr.push(String(level.students));
        arr.push(level.level + "س");
        array.push(arr);
      }

      // fix table
      for (let row = 0; row < array.length; row++) {
        array[row] = array[row].filter((cell) => cell !== cellToDelete);
      }

      doc.setFontSize(13);
      doc.setFont("Amiri", "Bold");
      doc.setTextColor("#505050");
      doc.text(
        "الجمهورية التونسية\nوزارة التربية\nالمندوبية الجهوية للتربية ب",
        pdf_width - 17,
        15,
        { align: "right" }
      );

      doc.setFontSize(16);

      doc.text(
        `نتائج تلاميذ خلال ${titleTrimester} للسنة الدراسية ${this.schoolarYearName}`,
        250,
        15,
        { align: "right" }
      );

      doc.setFontSize(12);

      doc.text(
        `المدرسة الإبتدائية الخاصّة : ${
          this.schoolDetails.ArabicName || ".............."
        }`,
        250,
        25,
        {
          align: "right",
        }
      );

      doc.text(`جدول عدد ${++module_id}`, 18, 15);

      doc.addImage(imgData, "JPEG", pdf_width - 100, 10, 20, 20);

      doc.autoTable({
        theme: "grid",
        styles: {
          font: "Amiri",
          halign: "right",
          fontSize: 12,
          halign: "center",
          valign: "middle",
          fontStyle: "Bold",
          lineColor: "#363636",
          lineWidth: 0.1,
        },
        pageBreak: "avoid",
        columnStyles: {
          0: { cellWidth: 22 },
          1: { cellWidth: 35 },
          2: { cellWidth: 35 },
          3: { cellWidth: 35 },
          4: { cellWidth: 35 },
          5: { cellWidth: 35 },
        },
        body: array,
        margin: { top: 35 },
        didDrawPage: function (data) {
          // Reseting top margin. The change will be reflected only after print the first page.
          data.settings.margin.top = 10;
        },
      });
      array = [];
      if (this.selectedBType != "MS") {
        arr = [
          {
            content:
              "عدد التلاميذ المتحصلين على معدل ثلاثي عام في المواد الأساسية",
            colSpan: 10,
            styles: {
              fontSize: 20,
            },
          },
          {
            content: "",
            colSpan: 2,
          },
        ];
        array.push(arr);
        arr = [];
        arr.push({
          content: "النسبة المائوية",
          rowSpan: 2,
        });
        arr.push({
          content: "مجموع المتحصلين على معدّل",
          rowSpan: 2,
        });

        arr.push({
          content: "دون 10 من 20",
          colSpan: 2,
        });

        arr.push({
          content: "يتراوح بين 10 و 14.99",
          colSpan: 2,
        });

        arr.push({
          content: "يتراوح بين 15 و 15.99",
          colSpan: 2,
        });

        arr.push({
          content: "يساوي او يفوق 16",
          colSpan: 2,
        });

        arr.push({
          content: "عدد التلاميذ",
          rowSpan: 2,
        });

        arr.push({
          content: "المستوى",
          rowSpan: 2,
        });

        array.push(arr);

        arr = [];

        for (let i = 0; i < 4; i++) {
          arr.push({
            content: "%النسبة",
          });
          arr.push({
            content: "العدد",
          });
        }

        arr.push(cellToDelete);
        arr.push(cellToDelete);

        array.push(arr);

        for (const level of this.rootStat["MS"].general) {
          arr = [];
          arr.push(level.hasMoyenneAverage);
          arr.push(level.hasMoyenne);
          arr.push(level.lt10Average);
          arr.push(level.lt10);
          arr.push(level.between10And14Average);
          arr.push(level.between10And14);
          arr.push(level.between15And16Average);
          arr.push(level.between15And16);
          arr.push(level.gte16Average);
          arr.push(level.gte16);
          arr.push(String(level.students));
          arr.push(level.level + "س");
          array.push(arr);
        }
      }
      // fix table
      for (let row = 0; row < array.length; row++) {
        array[row] = array[row].filter((cell) => cell !== cellToDelete);
      }

      doc.autoTable({
        theme: "grid",
        styles: {
          font: "Amiri",
          halign: "right",
          fontSize: 12,
          halign: "center",
          valign: "middle",
          fontStyle: "Bold",
          lineColor: "#363636",
          lineWidth: 0.1,
        },
        pageBreak: "avoid",
        columnStyles: {
          0: { cellWidth: 22 },
          1: { cellWidth: 35 },
          2: { cellWidth: 35 },
          3: { cellWidth: 35 },
          4: { cellWidth: 35 },
          5: { cellWidth: 35 },
        },
        body: array,
        margin: { top: 35 },
        didDrawPage: function (data) {
          // Reseting top margin. The change will be reflected only after print the first page.
          data.settings.margin.top = 10;
        },
      });

      doc.setFontSize(15);
      doc.text(
        moment().format("YYYY/MM/DD"),
        200,
        doc.lastAutoTable.finalY + 10
      );

      //page numbering
      const pages = doc.internal.getNumberOfPages();
      const pageWidth = doc.internal.pageSize.width;
      const pageHeight = doc.internal.pageSize.height;
      doc.setFontSize(8); //Optional

      for (let j = 1; j < pages + 1; j++) {
        let horizontalPos = pageWidth / 2;
        let verticalPos = pageHeight - 10;
        doc.setPage(j);
        doc.text(`Page ${j} / ${pages}`, horizontalPos, verticalPos, {
          align: "center",
        });
      }

      doc.save("Statistiques.pdf");

      this.printLoading = false;
    },
  },
};
</script>
<style scoped>
.success .ant-btn-danger {
  background: #8bc34a !important;
  border-color: #8bc34a !important;
}
</style>
<style>
.no-page-break {
  page-break-inside: avoid;
  margin-top: 5px;
}

@media print {
  .ant-tabs-tab {
    display: none !important;
  }
  .ant-divider {
    page-break-after: always;
  }

  .apexcharts-menu-icon {
    display: none !important;
  }
}
</style>
